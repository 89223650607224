/* Slider header theme.
------------------------------------------------------------ */
.flexslider {
  position: relative;
  background: #f3f3f3 url("../images/balls.svg") no-repeat 50% 50%;
  /*opacity: 0;
  transition: .4s;*/
  overflow: hidden;
  @include break($mobile) {
    background: $purple;
  }
  .slides {
    > li {
      overflow: hidden;
      position: relative;
      display: none;
      &:first-child {
        display: block;
      }
    }
  }

  // Caption text.
  .caption-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, 0);
    transition: all 0.5s ease-out .5s;
    &.left{
      right: auto;
      left: 13%;
      @include break($mobile) {
       left: 40px;
      }
      @include break($x-small) {
        left: 20px;
      }
   }
    &.right{
      right: 13%;
      @include break($mobile) {
       right: 40px;
      }
      @include break($x-small) {
        right: 20px;
      }
    }
    &.center{
      top: 50%;
      left: 50%;
      transform:translate(-50%, 0);
    }
    .flex-caption {
      font-family: $main_font;
      @include font(1.81rem, 1.3, #fff, 400);
      text-align: center;
      text-transform: uppercase;
      @include break($medium) {
      }
      @include break($x-small) {
      }
      em{
        @include font(1rem, 1, #fff, 400);
        letter-spacing: .5rem;
        font-style: normal;
        display: inline-block;
        position: relative;
        padding-bottom:10px;
        margin-bottom: 25px;
        &:before{
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          left: 50%;
          transform:translateX(-50%);
          width: 30px;
          height: 2px;
          background: $turquoise;
        }
      }
      h1{
        @include font(1.25rem, 1.2, #fff, 400);
        letter-spacing: 1.25rem;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        padding: 0 0 18px;
        margin: 0 0 26px;
        &:before{
          display: none;
        }
        &:after{
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          left: 50%;
          transform:translateX(-50%);
          width: 30px;
          height: 2px;
          background: $turquoise;
        }
        br{
          display: none;
        }
        strong{
          @include font(4.6rem, 1.2, #fff, 600);
          letter-spacing:normal;
          display: block;
          em{
            @include font(4.6rem, 1.2, #fff, 300);
            letter-spacing:normal;
            padding: 0;
            margin: 0;
            &:before{
              display: none;
            }
          }
        }
      }
      p {
        padding: 0;
      }
      .btn{
        margin: 34px 0 0;
      }
    }
  }

  // Animate current slide caption & on initial slide load.
  .active-slide,
  .slide-loaded {
    .caption-wrap {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
  // Left/right arrows.
  .flex-direction-nav {
    top: 50%;
    width: 100%;
    margin-top: -24px;
    position: absolute;
    @include break($large) {
    }
    @include break($mobile) {
      display: none!important;
    }
    li {
      display: inline;
    }
    a {
      display: block;
      font-size: 0;
      line-height: 0;
      text-decoration: none;
      transition: all 0.3s ease 0s;
      position: absolute;
      z-index: 11;
      color: #fff;
      width: 48px;
      height: 48px;
      text-align: center;
      &::before {
        font-family: $font_awesome;
        font-size: 45px;
        line-height: 48px;
        color: #fff;
        text-align: center;
        z-index: 10;
        position: relative;
        transition: .2s ease-in-out;
        opacity:.3;
      }
      &::after{
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #000;
        border-radius: 50%;
        opacity: .2;
        z-index: 9;
        transition: .2s ease-in-out;
      }
      &.flex-prev {
        left: -100px;
        &:before {
          content: "\f104";
          margin-right: 5px;
        }
      }
      &.flex-next {
        right: -100px;
        &:before {
          content: "\f105";
          margin-left: 5px;
        }
      }
      &:hover {
        &::before{
          color: #000;
          opacity:1;
        }
        &::after{
          opacity: 1;
          background: #ffeab5;
        }
      }
    }
  }
  &:hover{
    .flex-direction-nav{
      a{
        &.flex-prev, &.flex-next{
          transition: .2s ease-in-out;
        }
        &.flex-prev{
          left: 30px;
        }
        &.flex-next{
          right: 30px;
        }
      }
    }
  }

  // Slider pagination(dots).
  .flex-control-nav {
    font-size: 0;
    line-height: 0;
    position: absolute;
    bottom: 76px;
    z-index: 2;
    text-align: center;
    width:100%;
    @include break($medium) {
    }
    @include break($mobile) {
      //display: none;      
      bottom: 15px;
      display: none;      
    }
    li {
      display: inline-block;
      margin: 0 12px;
      a {
        cursor: pointer;
        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        border: solid 2px transparent;
        transition:none;
        box-shadow: inset 0px 0px 0px 1px rgba(255,255,255,1);
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(216, 216, 216, 0.33);
          opacity: 1;
        }
        &:after{
          content: '';
          position: absolute;
          display: block;
          width: 10px;
          height: 10px;
          background: #fff;
          top: 50%;
          left: 50%;
          transform:translate(-50%, -50%);
          opacity: 0;
        }
        &:hover {
          border:1px solid #fff;
          box-shadow: none;
          margin-bottom: -1px;
          &:after{
            opacity: 1;
          }
        }
        &.flex-active {
          border:1px solid #fff;
          box-shadow: none;
          margin-bottom: -1px;
          &:after{
            opacity: 1;
          }
        }
      }
    }

  }

  // Slider hover.
  .non-touch &:hover {
    .flex-direction-nav {
      display: block;
    }
  }

  // Show slider on page load.
  .loaded & {
    opacity: 1;
    .slides {
      > li {
        display: block;
      }
    }
  }
}
