// ++++++++++++++++++++++++ Variables ++++++++++++++++++++++++

// Fonts.
$courgette: 'Courgette', cursive;
$montserrat: 'Montserrat', sans-serif;
$oswald: 'Oswald', sans-serif;
$open_sans:'Open Sans', sans-serif;

$main_font: $open_sans;
$second_font: $courgette;
$third_font: $oswald;
$ourth_font: $montserrat;

$font_awesome: FontAwesome;
$ionicons: 'Ionicons';
$simple_line_icons: 'simple-line-icons';
$font_woocommerce: 'WooCommerce';
$mat_icons: 'Material Icons';


// Colors.
$beige: #d9a492;
$red: #ed0f2a;
$red_light: #ff3333;
$reb_dark: #7e394e;
$pink: #d9a492;
$pink_light: #ffcfbc;
$green: #3ab862;
$mint_green: #56cbbf;
$blue: #1a5ca1;
$blue_light: #155ca5;
$blue_dark: #003e72;
$turquoise:#50d9e3;
$orange: #ef783d;
$yellow: #ffdb4f;
$golden: #ebd396;
$purple: #4e4e4e;
$grey: #3ab862;
$grey_xslight: #f4f4f4;
$grey_light: #f0f0f0;
$grey_dark: #787878;
$grey_black: #2b2b2b;
$white_light: #f2f2f2;

$error_bg: #f7d7d7;
$color_primary: #303030;
$color_secondary: #000;
$hover_color: #ff7b91;

$color_link: #4e4e4e ;
$color_account_second: #fff;

$available: #6dc3ac;

$font_color_main: #1d1d1d;

// ++++++++++++++++++++++++ Mixins ++++++++++++++++++++++++ */

// Adds circles via styles(normally on ::before/::after).
@mixin circle($size: 10px, $color: #fff) {
	width: $size;
	height: $size;
	background: $color;
	border-radius: 50%;
	content: '';
	display: inline-block;
}

// Clear floating.
@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin font($font_size:14px, $line_height:1.5, $color:$color_primary, $font_weight: 400) {
	font-size: $font_size;
	line-height: $line_height;
	color: $color;
	font-weight: $font_weight;
}

// Link hover effect 1(text overlay).
@mixin hover_text_overlay($color:darken($blue, 10%)) {
	position: relative;
	display: inline-block;
	&::before {
		color: $color;
		content: attr(data-hover);
		position: absolute;
		opacity: 0;
		transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
		transition: transform 0.3s, opacity 0.3s;
		pointer-events: none;
	}
	&:hover {
		outline: none;
		&::before {
			transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
			opacity: 1;
		}
	}
}

@mixin link_trans($tra: .15s) {
  -webkit-transition: all $tra ease;
     -moz-transition: all $tra ease;
      -ms-transition: all $tra ease;
       -o-transition: all $tra ease;
          transition: all $tra ease;
}


// Link hover effect 2(underline).
@mixin hover_underline($color:$blue) {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 1px;
		background: $color;
		opacity: 0;
		transition: opacity 0.3s, transform 0.3s;
		transform: translateY(-10px);
	}
	&:hover,
	&:focus {
		outline: none;
		&::before {
			opacity: 1;
			transform: translateY(-5px);
		}
	}
}

// ++++++++++++++++++++++++ Animations ++++++++++++++++++++++++ */
//
@keyframes fadeOutText {
	0% {
		color: transparent;
	}
	80% {
		color: transparent;
	}
	100% {
		color: #fff;
	}
}

/* Animations */
// Spin animation
.fa-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@keyframes fillWidth {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@keyframes shake {
	0%, 100% {
		transform: translate3d(-10px, 0, 0)
	}
	50% {
		transform: translate3d(10px, 0, 0)
	}
}
@mixin inherit() {
	font-size: inherit;
	font-style: inherit;
	font-family:inherit;
	line-height:inherit;
	text-transform: inherit;
	color:inherit;
	font-weight:inherit;
	padding: 0;
	margin: 0;
	text-align: inherit;
	&:before, &:after{
		display: none;
	}
}
@mixin iconbefore( $glyph: "\e001" ) {
	font-family: $font_woocommerce;
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	margin-right: .618em;
	content: $glyph;
	text-decoration: none;
}

@mixin iconafter( $glyph: "\e001" ) {
	font-family: $font_woocommerce;
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	margin-left: .618em;
	content: $glyph;
	text-decoration: none;
}
@mixin no-before-after() {
	&:before, &:after{
		display: none;
	}
}
@mixin transition($time:.2) {
	transition: $time+s ease-in-out;
}

