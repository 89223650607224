/* -------------------------------------------------------------------------------------------------------------------
                                                  General elements
------------------------------------------------------------------------------------------------------------------- */
* {
  box-sizing: border-box;
}

body {
  font-family: $main_font;
  @include font(16px);
  background: #fff;
  //background-image: linear-gradient(251deg, #381923, #7e394e 58%, #622b3c 84%, #502431);
  color: #1a1a1a;
  @include break($neutral) {
    font-size: 14px;
  }
  @include break($mobile) {
  }
  @include break($x-small) {
    min-width: 320px;
  }
}

.main-column{
  background: #f4f2f1;
  @include clearfix();
  padding-bottom: 50px;
  &.work-page{
    padding-bottom: 0;
  }
  .home & {
    padding:  100px 0 60px;
    @include break($neutral) {
      padding-top: 60px;
    }
    @include break($mobile) {
      padding-top: 40px;
    }
    .btn{
      margin-top: 30px;
    }
  }
  .not-home & {
  }
}
.entry-content{
  @include break($medium) {
    padding: 0;
  }
  .woocommerce-page &{
    padding: 0;
  }
  .woocommerce-account.woocommerce-page &,
  .woocommerce-page.woocommerce-order-received &{
    padding: 0 17% 0 17%;
    @include break($medium) {
      padding: 0;
    }
  }
}


img {
  max-width: 100%;
  height: auto;
}

p {
  padding-bottom: 10px;
  margin: 0;
}

a {
  color: $color_link;
  text-decoration: underline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: .2s ease-in-out;
  &:hover {
    outline: none;
    text-decoration: none;
    color: $color_link;
  }
  &:focus {
    outline: none;
  }
  &[href^='tel:'] {
    cursor: default;
    color: inherit;
    text-decoration: none;
  }
}

strong {
  font-weight: 700;
}

iframe {
  max-width: 100%;
}

blockquote {
  margin-top: 20px;
  padding: 0 0 30px 85px;
  font-style: italic;
  position: relative;
  @include font(16px, 1.4, #1a1a1a, 400);
  &::before {
    //content: "\f10d";
    content: url(../images/quotes.svg);
    font-style: normal;
    //font-family: $font_awesome;
    position: absolute;
    top: 5px;
    left: 35px;
  }
  strong {
    font-style: normal;
  }
}

hr {
  display: block;
  height: 1px;
  background: #cacaca;
  margin: 25px 0;
  border: none;
}

.clear {
  @include clearfix();
}

.block.bordered{
  padding: 0 40px 42px;
  border: solid 1px #d8d8d8;
  .text{
    font-size: 20px;
  }
  h3{
    margin: 0 -40px 45px;
  }
}

.scrolloff {
  pointer-events: none;
}

//lightbox styles
.lg-toolbar {
  background: $turquoise;
  opacity:.9;
}
.lg-actions{
  .lg-icon{
    padding: 0;
    margin: 0;
    background: none;
    border-radius: 0;
    &.lg-prev{
      left: 8vw;
      &:after{
        content: '\f104';
        font-family:$font_awesome;
        font-size: 50px;
        color: #000;
      }
    }
    &.lg-next{
      right: 8vw;
      &:before{
        content: '\f105';
        font-family:$font_awesome;
        font-size: 50px;
        color: #000;
      }
    }
    &:hover{
      &:before, &:after{
        color: $turquoise;
      }
    }
  }
}
.lg-close.lg-icon{
  margin: 20px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  &:after{
    content: '\e082';
    font-family:$simple_line_icons;
    color: #fff;
    font-size: 40px;
  }
}
.lg-backdrop{
  background: rgba(255, 255, 255, .98)!important;
}

/* Buttons & Links
------------------------------------------------------------ */
// Default button.
.btn {
  font-family: $courgette;
  @include font(18px, 50px, #fff, 400);
  text-decoration: none;
  display: inline-block;
  position: relative;
  outline: none;
  transition: .2s ease-in-out;
  border: 0;
  background: #9fb706;
  border-radius: 30px;
  cursor: pointer;
  float: none;
  width: auto;
  padding: 0 21px;
  width: 180px;
  text-align: center;
  @include break($medium) {
    font-size: 13px;
  }
  @include break($neutral) {
    padding: 0 20px;
  }
  @include break($mobile) {
    font-size: 12px;
  }
  &:hover,
  &:active {
    color: #ff7c92;
    background: #fff;
    text-decoration: none;
  }
  &:active {
    //top: 2px;
  }
  // Spacing if more than 1 item.
  + a {
    margin-left: 10px;
  }
}

// Custom button.
.btn-custom {
  font-family: $main_font;
  @include font(14px, 42px, $turquoise, 400);
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  border: 1px solid $turquoise;
  padding: 0 21px;
  transition: .2s ease-in-out;
  border-radius: 4px;
  &:hover{
    color: #fff;
    background: $hover_color;
  }
}

// Large button.
.btn-large {
  display: inline-block;
  font-family:$second_font;
  @include font(20px, 40px, #000, 700);
  background: #ebd396;
  padding: 0 22px;
  text-decoration: none;
  height: auto;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0px 2px rgba(235,211,150,1);
  transition: .2s ease-in-out;
  margin-top: 30px;
  &:hover {
    background: transparent;
    color: #ebd396;
    box-shadow: 0px 0px 0px 2px rgba(235,211,150,1);
    border-color: transparent;
  }
  .mce-content-body & {
    background: #eee;
    &:hover {
      background: #c6e2ff;
    }
  }
  .center &{
    text-align: center;
  }
  @include break($medium){
  }
  @include break($mobile){
    font-size: 16px;
    margin: 15px 0 15px;
  }
  @include break($x-small){
    display: block;
  }
}

// Primary link.
.link,
.details {
  color: $blue;
  font-style: italic;
  font-size: 16px;
  position: relative;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  padding-bottom: 2px;
  // Underline transition effects.
  &:after,
  &:before {
    content: "";
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    position: absolute;
    transition: all 0.5s ease;
  }
  &:after {
    background: #aaa;
    transform: translate(0, 0);
  }
  &:before {
    background: $blue;
    transform: translate(300px, 0);
  }
  &:hover {
    color: $blue;
    text-decoration: none;
    &:after {
      transform: translate(300px, 0);
    }
    &:before {
      transform: translate(0, 0);
    }
  }
}

// Custom link.
.more {
  font-family: $third_font;
  @include font(16px, 40px, #4a4a4a, 400);
  text-decoration: none;
  text-transform: none;
  display: inline-block;
  text-align: center;
  border: 1px solid #979797;
  padding: 0 15px;
  transition: .2s ease-in-out;
  border-radius: 4px;
  &:hover{
    color: #fff;
    background: $hover_color;
    border-color: $hover_color;
  }
}
 //Back button
.back{
  @extend .more;
  padding-left: 50px;
  position: relative;
  margin-top: 22px;
  &:before{
    content: '\f177';
    position: absolute;
    display: block;
    font-family:$font_awesome;
    font-size:20px;
    left: 18px;
    top: 50%;
    transform:translateY(-50%);
    @include transition();
  }
  &:hover{
    &:before{
      left: 8px;
      color: #fff;
    }
  }
}


/* Default lists */
.list ul,
ul.list {
  .not-home &, .woocommerce-page & {
    margin: 10px 0 20px;
    li {
      padding: 0 0 7px 35px;
      position: relative;
      &::before {
        @include circle(7px, #37a95e);
        position: absolute;
        top: 9px;
        left: 10px;
      }
      ul {
        margin: 15px 0 0 0;
        li {
          font-size: 14px;
          padding-bottom: 5px;
        }
      }
    }
  }
}

/* Numbered list */
.list ol,
.entry-content ol,
ol.list {
  list-style: inside decimal;
  padding-bottom: 10px;
  li {
    padding-left: 0;
    line-height: 1.5;
    padding-bottom: 10px;
    ol {
      font-size: 13px;
      padding: 6px 0 6px 14px;
    }
  }
  .text-indent {
    padding-left: 35px;
  }
}

.red {
  color: $red;
}

/* Default classes.
------------------------------------------------------------ */
.wrapper {
  @include clearfix();
}

.spacer {
  height: 25px;
  .site-all & {
    font-size: 0;
  }
}

.clear {
  clear: both;
  float: none;
}

/* Tables inside content pages.
------------------------------------------------------------ */
article,
.table {
  table {
    width: 100%;
    margin-bottom: 30px;
    tr {
      background: #ededed;
      border-bottom: 1px solid #fff;
      &:nth-child(2n+1) {
        background: #f7f7f7;
      }
      &:hover {
        background: darken($white_light, 10%);
      }
      > *:first-child {
        text-align: left;
        border-left: none;
      }
    }
    th {
      padding: 5px 5px 5px 20px;
      font-size: 18px;
      font-weight: 600;
      border-left: 1px solid #fff;
      color: #fff;
      background: $color_link;
      @include break($x-small) {
        padding-left: 10px;
        font-size: 15px;
        white-space: nowrap;
      }
    }
    td {
      font-size: 14px;
      color: #202020;
      border-left: 1px solid #fff;
      padding: 5px 5px 5px 20px;
    }
  }
}

div.table {
  overflow-x: auto;
  margin-bottom: 30px;
}

/* Images.
------------------------------------------------------------ */
.wp-post-image {
}

.alignleft {
  float: left;
  display: block;
  margin: 0 15px 10px 0;
  @include break($neutral) {
    float: none;
    display: block;
    margin-right: 0;
  }
}

.alignright {
  float: right;
  margin: 0 0 10px 15px;
  @include break($neutral) {
    float: none;
    display: block;
    margin-left: 0;
  }
}

.aligncenter {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

img.border {
  padding: 5px;
  border: 1px solid #c3c3c3;
  border-radius: 2px;
}

.featured-thumbnail {
  figcaption {
    margin-top: 10px;
  }
}

/* Titles
------------------------------------------------------------ */
h1 {
  font-family:$main_font;
  @include font(29px, 1.2, #1d1d1d, 400);
  text-transform: uppercase;
  padding-bottom:12px;
  position: relative;
  margin: 0 0 .6em;
  text-align: center;
  &:before{
    content: '';
    position: absolute;
    display: block;
    background: $turquoise;
    width: 30px;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform:translateX(-50%);
  }
  @include break($large) {
    font-size: 38px;
  }
  @include break($medium) {
    font-size: 30px;
  }
  @include break($neutral) {
    font-size: 27px;
  }
  @include break($mobile) {
    font-size: 24px;
  }
  .home & {
    @include font(50px, 1.1, #fff, 700);
    text-transform: uppercase;
    font-family: $main_font;
    text-align: left;
    margin: 0 0 24px;
    padding: 0;
    &::before, &::after{
      display: none;
    }
    @include break($mobile) {
    }
    @include break($x-small) {
    }
  }
}


h2 {

  .home &{
    font-family: $oswald;
    @include font(37px, 1.2, #444f5c, 400);
    padding: 0 0 20px;
    margin: 0 0 15px;
    text-align: center;
    position: relative;
    @include break($neutral) {
      font-size: 30px;      
    }      
    @include break($mobile) {
      font-size: 24px;
      padding-bottom: 5px;
    }
  small {
    display: block;
    font-size: 18px;
  }
  }
}

h3 {
  @include font(24px, 1.3, #000, 300);
  font-family: $main_font;
  margin: .6em 0 .5em;
  background: $turquoise;
  padding: 15px 40px 16px;
  text-transform: uppercase;
  @include break($medium) {
  }
  @include break($neutral) {
    font-size: 18px;
  }
  @include break($mobile) {
    font-size: 24px;
  }
  .home & {
    @include font(5rem, 1.3, #000, 300);
    font-family: $third_font;
    text-transform: none;
    padding: 0;
    margin: 0;
    position: relative;
    text-align: center;
    background: none;
    @include no-before-after();
  }
}

h4 {
  font-family:$main_font;
  @include font(22px, 1.3, #1a1a1a, 700);
  margin: .6em 0 .5em;
  @include break($medium) {
  }
  @include break($mobile) {
    font-size: 14px;
  }
  .home & {
    font-family:$main_font;
    @include font(40px, 1.3, #fff, 700);
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    &:before, &:after{
      display: none;
    }
  }
}


h5 {
  @include font(30px, 1.3, #1a1a1a, 300);
  margin: .6em 0 .5em;
  text-align: left;
  @include break($medium) {
  }
  @include break($mobile) {
    font-size: 15px;
  }
  .home &{
    padding: 0;
    background: none;
  }
}

h6 {
  @include font(18px, 1.3, #1a1a1a, 700);
  margin: .6em 0 .5em;
}

.featured-text {
  @include clearfix();
  padding: 20px 0;
  border-top: 3px solid #dcdcdc;
  border-bottom: 3px solid #dcdcdc;
  margin: 15px 0 25px;
  font-size: 36px;
  font-weight: 300;
  @include break($medium) {
    font-size: 32px;
  }
  @include break($neutral) {
    font-size: 22px;
  }
  @include break($x-small) {
    font-size: 18px;
    text-align: center;
  }

  .btn {
    float: right;
    @include break($x-small) {
      float: none;
      margin-top: 12px;
    }
  }
}

/*Contact Page*/
.contact-page{
  .block {
    padding-left: 10%;
  }
  .text{
    margin-bottom: 10px;
    strong{
      display: inline-block;
      width: 90px;
      font-weight: normal;
    }
    .phone-number{
      @include font(30px, 1, #000);
    }
    .email{
      text-decoration: none;
      color: #000;
    }
  }
}

