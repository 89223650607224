// ++++++++++++++++++++++++ Breakpoints ++++++++++++++++++++++++
// Breakpoints variables (required by break @mixin).
$Xlarge: x-large;
$large: large;
$desktop: desktop;
$medium: medium;
$neutral: neutral;
$mobile: mobile;
$not-mobile: not-mobile;
$x-small: x-small;
$xs-small: xs-small;

// Consistent with bootstrap breakpoints.
@mixin break($point) {
	@if $point == 1600 {
		@media (max-width: 1600px) {
			@content;
		}
	}
	@if $point == x-large {
		@media (max-width: 1729px) {
			@content;
		}
	} @else if $point == large {
		@media (max-width: 1329px) {
			@content;
		}
	} @else if $point == desktop {
		@media (min-width: 1330px) {
			@content;
		}
	} @else if $point == medium {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point == neutral {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point == not-mobile {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point == x-small {
		@media (max-width: 479px) {
			@content;
		}
	} @else if $point == xs-small {
		@media (max-width: 360px) {
			@content;
		}
	}
}