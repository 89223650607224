/* Main Theme Styles file */
// Major components.
@import "reset";
//@import "grid/variables";
//@import "grid/breakpoints";
@import "breakpoints";
@import "variables";
@import "elements";
@import "functional";
@import "header";
@import "footer";
@import "slider_theme";
@import "owl.carousel.core";
//@import "magnific_popup";
//@import "gravity_forms";
@import "editor-style";

// Import google fonts
@import url('https://fonts.googleapis.com/css?family=Courgette|Montserrat:300,400,400i,500,600,700|Open+Sans:300,400,600,700|Oswald:300,400,500,600,700&display=swap&subset=latin-ext');
/* Home page
------------------------------------------------------------ */
.site {
	opacity: 0;
	visibility: hidden;
	@include transition();
	.loaded &  {
		visibility: visible;
		opacity: 1;
	}
}
div#preloader {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	overflow: visible;
	background: #fff url('../images/fotomobile-loader.svg') no-repeat center center;
}

.three-blocks {
	width: 100%;
	background: $grey_xslight;
	overflow: hidden;

	.block {
		padding: 8vw 14vw 2.5vw 3vw;
		width: 33.333333%;
		float: left;
		position: relative;
		box-shadow: inset 1px 0px 0px 0px rgba(255, 255, 255, 1);
		@include font(18px, 1.3, $font_color_main, 400);
		font-family: $second_font;

		&:before {
			content: '1';
			position: absolute;
			display: block;
			bottom: 0;
			right: 2vw;
			margin-bottom: -2.5vw;
			opacity: 0.7;
			@include font(20vw, 1, #fff, 400);
		}

		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 30px;
			height: 2px;
			left: 3vw;
			top: 7.3vw;
			background: $turquoise;
		}

		&:first-child {
			box-shadow: none;
		}

		&:nth-child(2) {
			&:before {
				content: '2';
			}
		}

		&:nth-child(3) {
			&:before {
				content: '3';
			}
		}

		&:nth-child(4) {
			&:before {
				content: '4';
			}
		}

		p {
			padding: 0;
		}
	}
}

.our-work {
	padding: 3.5vw 0 0;
}


/* -------------------------------------------------------------------------------------------------------------------
                                                  Pages & Post Types
------------------------------------------------------------------------------------------------------------------- */


/*
* Contact page
 */
.main-column.contact-page {
	h2 {
		text-align: left;
		@include font(30px, 1.3, $font_color_main, 400);
		text-transform: none;
		margin: 0 0 11px;
		padding: 0 0 8px;

		&:before {
			left: 0;
			transform: translateX(0);
			background: $turquoise;
		}
	}

	.phone-number {
		@include font(40px, 1, $font_color_main, 400);
		font-family: $third_font;
		text-decoration: none;
	}

	.text {
		@include font(20px, 1.4, $font_color_main, 400);
		font-family: $third_font;
	}

	a {
		color: $turquoise;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}


.grid-oferta {
	margin: 0 -15px;
	@include break($mobile) {
		margin: 0;
	}
	.gutter-sizer { width: 1.5%; }
	.grid-sizer,
	.element-item  {
		width: 23.5%;
		margin: 0 0 25px;
		@include break($neutral) {
			width: 48.5%;
		}
		@include break($mobile) {
			width: 100%;
		}
	}
}

/*Gallery Page*/
.grid-sizer, .element-item {
	width: 25%;

	@include break($mobile) {
		width: 100%;
	}

}

.gallery-tabs {
	margin: 0 12px 20px;
	border-bottom: solid 1px #cacaca;

	.button-group {
		text-align: center;
		line-height: 0;
		font-size: 0;
	}

	.tab-button {
		display: inline-block;
		font-family: $second_font;
		@include font(15px, 1.67, #7e394e, 500);
		padding: 8px 16px 11px;
		text-transform: uppercase;
		cursor: pointer;
		transition: .2s ease-in-out;
		border: 1px solid transparent;
		border-bottom: none;
		@include break($medium) {
			padding-left: 12px;
			padding-right: 12px;
			font-size: 12px;
		}
		@include break($mobile) {
			display: block;
			border: none;
			font-size: 16px;
			line-height: 1.15em;
		}

		&:hover {
			background: #fcf1ef;
		}

		&.is-checked {
			border: solid 1px #cacaca;
			border-bottom: none;
			position: relative;
			pointer-events: none;
			@include break($mobile) {
				border: none;
			}

			&::before {
				position: absolute;
				content: '';
				display: block;
				bottom: -1px;
				left: 0;
				width: 100%;
				height: 1px;
				background: #fff;
			}
		}
	}
}

//Oferta tabs

.oferta-tabs {
	margin: 0 12px 40px;

	.button-group {
		text-align: center;
		line-height: 0;
		font-size: 0;
	}

	.tab-button {
		display: inline-block;
		font-family: $third_font;
		border-radius: 37px;
		background-color: #ff7a90;
		@include font(24px, 1.3, #fff);
		padding: 14px 30px 18px;
		text-transform: uppercase;
		cursor: pointer;
		transition: .2s ease-in-out;
		@include break($neutral) {
			font-size: 20px;
			padding: 10px 20px 14px;
		}
		@include break($mobile) {
			display: block;
			margin:0 0 10px;
			font-size: 16px;
			line-height: 1.15em;
		}

		&:hover {
			background: #fff;
			color: #ff7a90;
		}

		&.is-checked {
			background: #fff;
			color: #ff7a90;
			border-bottom: none;
			position: relative;
			pointer-events: none;
		}
		& + .tab-button {
			margin-left: 25px;
			@include break($mobile) {
				margin-left: 0;
			}
		}
	}
}


.animsition-loading {
	@include break($mobile) {
		display: none !important;
	}
}

/*
* Pretty Photo
 */
.pp_pic_holder.pp_woocommerce {
	.ppt {
		display: none !important;
	}
}

/*Category Output on Home Page*/
.category-hp-wrap {
	width: 100%;
	overflow: hidden;

	.category-item-hp {
		width: 25%;
		height: auto;
		position: relative;
		float: left;
		overflow: hidden;
		@include break($neutral) {
			width: 50%;
		}
		@include break($x-small) {
			width: 100%;
		}

		.cat-img-wrap {
			line-height: 0;
			font-size: 0;
			position: relative;

			img {
				transition: transform .35s ease 0s;
				transform: scale(1.01) translateZ(0);
			}

			&::before {
				content: '';
				position: absolute;
				display: block;
				width: 100%;
				height: 50%;
				left: 0;
				bottom: 0;
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
				opacity: .3;
				transition: .5s ease-in-out;
				z-index: 10;
			}
		}

		.cat-wrap {
			position: absolute;
			bottom: 44px;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			width: 100%;
			padding: 0 20%;
			z-index: 12;
			transition: .6s cubic-bezier(0.29, 0.51, 0.01, 0.83);
			@include break($mobile) {
				padding-bottom: 0;
				padding-left: 0;
				padding-right: 0;
			}
		}

		.btn-cat {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -120px;
			//transition: .1s ease-out;
			@include break($mobile) {
				bottom: -30px;
			}
		}

		h6 {
			font-family: $second_font;
			@include font(24px, 1.4, #fff, 400);
			text-transform: uppercase;
			@include break($Xlarge) {
				font-size: 20px;
			}
			@include break($large) {
				font-size: 16px;
			}
			@include break($medium) {
				font-size: 14px;
			}
			@include break($neutral) {
				font-size: 20px;
			}
			@include break($mobile) {
				margin-bottom: 30px;
			}

		}

		&:hover {
			.cat-img-wrap {
				&::before {
					height: 80%;
				}
			}

			img {
				transform: scale(1.05) translateZ(0);
				@include break($mobile) {
					transform: none;
				}
			}

			.cat-wrap {
				padding-bottom: 70px;
				@include break($mobile) {
					padding-bottom: 0
				}
			}

			.btn-cat {
				bottom: 0;
				transition: .6s cubic-bezier(0.29, 0.51, 0.01, 0.83);
				@include break($mobile) {
					bottom: -30px;
				}

			}
		}
	}
}


/*Plugin mailchimp styles*/
.mc4wp-form {
	display: inline-block;
	vertical-align: top;

	.mc4wp-form-fields {
		font-size: 0;
	}

	input[type="email"] {
		width: 274px;
		padding: 0 20px 0 50px;
		background: #fff;
		border-radius: 0;
		@include font(14px, 34px, #000);
		border-color: transparent;

		&:hover {
			border-color: transparent;
		}

		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: #95979c;
		}

		&::-moz-placeholder { /* Firefox 19+ */
			color: #95979c;
		}

		&:-ms-input-placeholder { /* IE 10+ */
			color: #95979c;
		}

		&:-moz-placeholder { /* Firefox 18- */
			color: #95979c;
		}

		&.mce_inline_error {
			background: #ef9898;

			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: #fff;
			}

			&::-moz-placeholder { /* Firefox 19+ */
				color: #fff;
			}

			&:-ms-input-placeholder { /* IE 10+ */
				color: #fff;
			}

			&:-moz-placeholder { /* Firefox 18- */
				color: #fff;
			}

		}
	}

	input[type="submit"] {
		background: #7e394e;
		padding: 0 40px 0 15px;
		@include font(16px, 36px, #fff, 400);
		border: none;
		transition: .2s ease-in-out;
		border-radius: 0;

		&:hover {
			background: #fde0da;
			color: #000;
		}
	}

	.mc-field-group {
		display: inline-block;
		position: relative;

		&:before {
			content: '\f0e0';
			display: block;
			position: absolute;
			left: 15px;
			font-size: 20px;
			line-height: 36px;
			color: #3c3f45;
			font-family: $font_awesome;
			pointer-events: none;
		}
	}

	.submit-wrap {
		display: inline-block;
		position: relative;
		vertical-align: top;

		&:before {
			content: '\f138';
			font-family: $font_awesome;
			position: absolute;
			display: block;
			@include font(20px, 36px, #fff);
			right: 12px;
		}
	}

	&.popup {
		display: block;
		margin-top: 29px;
		width: 100%;

		#mc_embed_signup_scroll {
			width: 65%;
			float: left;
			display: block;

			input[type="email"] {
				width: 100%;

				&.mce_inline_error {
					background: #f56b6b;
				}
			}
		}

		.submit-wrap {
			width: 35%;
			float: left;
			display: block;

			input {
				width: 100%;
			}
		}
	}
}

/*   K o n t a c t  */

#kontakt {
	background: #f4f2f1;
	padding: 90px 0 80px;
	@include break($medium) {
		padding-bottom: 40px;
	}
	@include break($mobile) {
		padding-top: 40px;
	}

	.text {
		background: url('../images/phone-pic.png') 15px top no-repeat;
		padding-left: 140px;
		padding-bottom: 180px;
		margin-left: 60px;
		margin-top: 20px;

		@include break($large) {
			margin-left: 20px;
		}
		@include break($mobile) {
			margin-left: 0;
			margin-top: 0;
			padding-bottom: 60px;
			background-size: 60px;
			padding-left: 80px;
		}


		p {
			@include font(30px, 1.4, #444f5c, 400);
			font-family: $courgette;
			padding-top: 15px;
			@include break($mobile) {
				padding-top: 0;
			}

		}

		a.phone-number {
			@include font(40px, 1, #444f5c, 400);
			font-family: $courgette;
			@include break($neutral) {
				font-size: 28px;
			}
		}

		& + img {
			margin-left: 40px;
			@include break($neutral) {
				margin-left: 0;
			}
			@include break($mobile) {
				margin-bottom: 40px;
			}
		}
	}

	.wpcf7 {
		max-width: 400px;
		@include break($mobile) {
			max-width: none;
		}
	}
}


/* H O M E   F A Q   */

#faq {
	background: #363f4a;
	padding: 90px 0 70px;
	@include break($mobile) {
		padding-top: 40px;
	}

	h2 {
		color: #f2edd5;
	}

	.center {
		text-align: center;
		max-width: 940px;
		margin-right: auto;
		margin-left: auto;
		@include font(20px, 1.4, #f2edd5, 400);
		font-family: $courgette;
		padding-bottom: 20px;
		@include break($medium) {
			font-size: 18px;
			max-width: 860px;
		}
		@include break($mobile) {
			font-size: 15px;
		}
	}

	h5 {
		@include font(30px, 1.4, #c3cb41, 400);
		font-family: $courgette;
		text-align: center;
		margin-bottom: 30px;
		@include break($x-small) {
			font-size: 24px;
		}
	}

	.faq-wrap {
		overflow: hidden;
		display: none;
	}

	.faq-item {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 25px;

		div.title {
			background: #9fb50e;
			@include font(22px, 1.4, #fff, 400);
			font-family: $courgette;
			margin-bottom: 20px;
			border-radius: 25px;
			padding: 10px 20px;
			@include break($medium) {
				font-size: 20px;
			}

		}

		p {
			@include font(18px, 1.4, #f2edd5, 400);
			font-family: $courgette;
			padding-left: 20px;
			padding-right: 20px;
			overflow: hidden;
			@include break($medium) {
				font-size: 16px;
			}

			img {
				margin-left: 25px;
				float: right;
			}
		}
	}

	span.read-more {
		display: block;
		text-align: center;
		@include font(18px, 1.4, #f2edd5, 400);
		font-family: $courgette;
		cursor: pointer;
		text-decoration: underline;
	}

	p.circle {
		text-align: center;
		padding-top: 35px;

		span {
			background: #d8d8d8;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			position: relative;
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	.open_faq {
		&:hover {
			span.read-more {
				color: #fff;
				text-decoration: none;
			}

			p.circle {
				color: #fff;
				text-decoration: none;
			}
		}
	}

}

/* H O M E   G A L L E R Y   */

#galeria {
	background: #f2ecd5 url('../images/bg-gallery.svg') 15px top no-repeat;
	background-size: cover;
	padding: 92px 0 0;
	@include break($neutral) {
		background: #f2ecd5;
	}
	@include break($mobile) {
		padding-top: 42px;
		background: #f2ecd5 url('../images/small-tv.svg') 50% 120px no-repeat;
		background-size: 308px 234px;

	}

	.container {

		@include break($mobile) {
			max-width: 220px;
			margin-left: auto;
			margin-right: auto;
		}


		h2 {
			margin-bottom: 20px;
		}

		.gallery-wrap {
			background: url('../images/tv.svg') 15px top no-repeat;
			padding-top: 100px;
			padding-bottom: 220px;
			position: relative;
			@include break($large) {
				padding-top: 80px;
				background-size: 98%;
			}
			@include break($medium) {
				padding-top: 70px;
			}
			@include break($neutral) {
				padding-top: 0;
				padding-bottom: 90px;
				background: none;
			}
		}
	}

	.owl-gallery {
		max-width: 800px;
		margin-left: 160px;
		padding-top: 10px;
		@include break($large) {
			margin-left: 150px;
			max-width: 740px;
		}
		@include break($medium) {
			margin-left: 120px;
			padding-top: 0;
			max-width: 600px;
		}
		@include break($neutral) {
			max-width: 100%;
			margin-left: 20px;
		}
		@include break($mobile) {
			padding-top: 42px;
			padding-left: 0px;
			max-width: 124px;
			margin-left: 8px;
		}


		a.owl-item {
			text-decoration: none;
			padding-bottom: 25px;
			padding-right: 12px;
			@include break($large) {
				max-width: 245px;
			}
			@include break($medium) {
				max-width: 200px;
				padding-bottom: 20px;
			}
			@include break($neutral) {
				max-width: 33%;
			}
			@include break($mobile) {
				max-width: 200px;
				padding-bottom: 0;
				padding-right: 0;
			}

			img {
				display: block;
				border-radius: 20px;
				@include link_trans();
			}

			&::after {
				content: '';
				position: absolute;
				display: block;
				font-family: 'FontAwesome';
				font-size: 30px;
				color: #dce75e;
				@include link_trans();
				opacity: 0;
				left: 50%;
				top: 50%;
				margin-left: -30px;
				margin-top: -50px;
				@include break($mobile) {
					display: none !important;
				}

			}

			&:hover {
				&::after {
					opacity: 1;
				}

				img {
					opacity: .85;
				}
			}
		}

		div.title {
			text-align: center;
			@include font(15px, 1.4, #1a7dab, 400);
			font-family: $courgette;
			padding-top: 5px;
			@include break($large) {
				font-size: 14px;
			}
			@include break($medium) {
				font-size: 12px;
			}
			@include break($neutral) {
				font-size: 12px;
			}
			@include break($mobile) {
				font-size: 12px;
			}
		}

		.owl-nav {
			text-align: center;
			max-width: 765px;
			padding-top: 40px;
			@include break($large) {
				max-width: 708px;
			}
			@include break($medium) {
				max-width: 560px;
				padding-top: 28px;
			}
			@include break($neutral) {
				max-width: 140px;
			}
			@include break($mobile) {
				padding-bottom: 10px;
				padding-top: 80px;
				max-width: 100%;
			}
		}

		.owl-prev {
			font-size: 0;
			line-height: 0;
			display: inline-block;
			margin: 0 22px;
			@include break($neutral) {
				margin: 0 5px;
			}

			&::before {
				content: '';
				position: relative;
				display: block;
				font-family: 'FontAwesome';
				font-size: 40px;
				color: #9fb314;
			}

			&:hover {
				&::before {
					color: #dce55f;
				}
			}
		}

		.owl-next {
			font-size: 0;
			line-height: 0;
			display: inline-block;
			margin: 0 22px;

			&::before {
				content: '';
				position: relative;
				display: block;
				font-family: 'FontAwesome';
				font-size: 40px;
				color: #9fb314;
			}

			&:hover {
				&::before {
					color: #dce55f;
				}
			}
		}
	}

	.client-btn {
		display: block;
		position: absolute;
		text-transform: uppercase;
		@include font(20px, 1.2, #fff, 400);
		font-family: $oswald;
		text-decoration: none;
		max-width: 210px;
		background: url('../images/custom-button.svg') 0 top no-repeat;
		height: 90px;
		text-align: center;
		padding-left: 60px;
		padding-right: 50px;
		padding-top: 15px;
		right: 150px;
		bottom: 180px;

		&:hover {
			color: #f2edd5;
		}

		@include break($neutral) {
			bottom: 40px;
			right: 10px;
			z-index: 100;
		}
		@include break($mobile) {
			position: absolute;
			bottom: 40px;
			right: 50%;
			margin-right: 0;
			width: 220px;
			margin-right: -100px;
		}
	}
}


/* H O M E   O F E R T A    */

#oferta {
	background: #d0e8f2 url('../images/bg-oferta.svg') 50% top no-repeat;
	padding: 170px 0 100px;
	position: relative;
	@include break($neutral) {
		padding-top: 140px;
	}
	@include break($mobile) {
		padding-bottom: 40px;
		background: #d0e8f2;
		padding-top: 75px;
	}

	&:before {
		content: '';
		position: absolute;
		display: block;
		background: url('../images/happy-top-pic.svg') 50% top no-repeat;
		top: 0;
		width: 100%;
		height: 116px;
		z-index: 10;
		@include break($mobile) {
			background-size: 120%;
		}

	}

	.wrapper {
		background: #fff;
		border-radius: 20px;
		position: relative;
		overflow: hidden;
		@include break($neutral) {
			margin-bottom: 25px;
		}
		.title {
			display: block;
			color: #1a7dab;
			margin: 0;
			padding: 30px 24px 10px;
			border-bottom: 1px #d6cecb solid;
			font-family: $courgette;
			font-size: 30px;
			font-weight: 400;
			@include break($medium) {
				padding-top: 20px;
				font-size: 24px;
			}
			@include break($mobile) {
				font-size: 30px;
			}

		}
		p {
			padding: 20px 24px 30px;
			@include font(18px, 1.5, #7d6d67, 400);
			font-family: $courgette;
			@include break($large) {
				font-size: 16px;
				padding-bottom: 10px;
			}
			@include break($medium) {
				font-size: 14px;
			}
			@include break($mobile) {
				font-size: 16px;
				padding-bottom: 20px;
			}

			strong {
				display: block;
				color: #1a7dab;
				padding-bottom: 10px;
				border-bottom: 1px #d6cecb solid;
				font-family: $courgette;
				font-size: 30px;
				font-weight: 400;
				@include break($medium) {
					font-size: 24px;
				}
				@include break($mobile) {
					font-size: 30px;
				}
			}
		}

		.text {
			background: #ff7b91;
			text-align: center;
			@include font(40px, 1.4, #fff, 400);
			font-family: $oswald;
			padding: 20px 0;
			@include break($medium) {
				font-size: 32px;
			}
			@include break($mobile) {
				font-size: 40px;
			}

			a {
				&:hover {
					span {
						text-decoration: none;
						color: #fff;
					}
				}
			}

			span {
				font-size: 25px;
				text-decoration: underline;
				color: #fff;
				display: block;
				line-height: 54px;
				@include break($medium) {
					font-size: 20px;
					line-height: 44px;
				}
			}
		}
	}

	.dodatkowe {
		padding-top: 25px;
		padding-bottom: 35px;
		@include break($mobile) {
			padding-bottom: 0;
		}

		h4 {
			@include font(30px, 1.5, #444f5c, 400);
			font-family: $courgette;
			text-transform: none;
			max-width: 350px;
			background: url('../images/dodatkowe.svg') 100% bottom no-repeat;
			padding-bottom: 90px;
			@include break($medium) {
				font-size: 20px;
				padding-top: 10px;
			}
			@include break($mobile) {
				background: none;
				padding-bottom: 0;
				max-width: none;
			}

		}

		h5 {
			@include font(24px, 1.5, #444f5c, 400);
			font-family: $courgette;
			text-transform: none;
			margin: 0;
			padding: 0 0 12px 0;
			@include break($neutral) {
				font-size: 20px;
			}
		}

		p {
			@include font(18px, 1.5, #444f5c, 400);
			font-family: $open_sans;
			@include break($medium) {
				font-size: 16px;
			}
			@include break($neutral) {
				font-size: 13px;
			}
		}
	}
}

.palaroid {
	a {
		display: block;
		padding: 10px;
		background: #fff;
		border-radius: 10px;
		text-decoration: none;
		margin-bottom: 15px;
		@include break($mobile) {
			padding: 4px;
		}

		& + br,
		& > br {
			display: none;
		}

		img {
			margin: 0;
			float: none;
			max-width: 100%;
			@include break($x-small) {

			}
		}

		.text {
			@include font(20px, 1.5, #1a7dab, 400);
			text-align: center;
			padding-top: 12px;
			padding-bottom: 12px;
			font-family: $courgette;
			@include break($medium) {
				font-size: 16px;
			}
			@include break($mobile) {
				font-size: 12px;
			}
			@include break($x-small) {
				font-size: 15px;
			}
		}

		&:hover {
			.text {
				color: #444f5c;
			}
		}
	}

	.col-xs-6 {
		@include break($x-small) {
			width: 100%;
			float: none;
		}
	}


}


/* H O M E   F o t o b u d k a   */

.fotobudka {
	.fotobudka-row {
		@include break($neutral) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	div {
		@include break($neutral) {
			float: none;
			width: 350px;
			margin-right: auto;
			margin-left: auto;
			img {
				display: block;
				margin-right: auto;
				margin-left: auto;
			}
		}
		@include break($x-small) {
			width: 310px;
		}

		&.item-1 {
			margin-left: -25px;
			@include break($medium) {
				margin-left: 0;
			}
			@include break($neutral) {
				order: 1;
				overflow: hidden;
			}

			p.text {
				max-width: 250px;
				float: left;
				@include break($large) {
					max-width: 220px;
				}
				@include break($medium) {
					max-width: 260px;
				}
				@include break($neutral) {
					float: none;
					overflow: hidden;
					padding-left: 25px;
					max-width: none;
				}
				@include break($x-small) {
					padding-left: 5px;
				}


			}

			strong {
				max-width: 180px;
				@include break($medium) {
					max-width: 260px;
				}
			}

			img {
				margin-top: 60px;
				margin-right: -20px;
				max-width: none;
				@include break($large) {
					max-width: 90%;
				}
				@include break($medium) {
					max-width: 70px;
					margin-top: 0;
					margin-right: 0;
					margin-bottom: -20px;
				}
			}

			.alignleft {
				@include break($neutral) {
					float: left;
					padding-top: 30px;
				}
			}
		}

		&.item-2 {
			@include break($neutral) {
				order: 3;
			}

			p.text {
				max-width: 230px;
				@include break($neutral) {
					float: none;
					text-align: center;
					max-width: none;
				}

			}

			img {
				margin-bottom: -20px;
				margin-top: 20px;
				@include break($neutral) {
					margin-bottom: 0;
					margin-top: 0;
				}
			}
		}

		&.item-3 {
			@include break($neutral) {
				order: 5;
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				bottom: -10px;
				right: 80px;
				width: 31px;
				height: 76px;
				background: url('../images/arrow-small.png') 100% bottom no-repeat;
				@include break($medium) {
					right: 50px;
				}
				@include break($neutral) {
					display: none;
				}
			}

			p.text {
				max-width: 230px;
				margin-left: 40px;
				@include break($neutral) {
					float: none;
					text-align: center;
					max-width: none;
				}

			}

			img {
				margin-bottom: -20px;
				margin-top: 20px;
				margin-left: -40px;
				@include break($neutral) {
					margin-left: auto;
					margin-bottom: 0;
					margin-top: 0;
				}
			}
		}

		&.item-4 {
			clear: both;
			margin-top: -40px;
			padding-top: 100px;
			@include break($neutral) {
				order: 2;
				margin-top: 0;
				padding-top: 0;
			}

			img {
				margin-top: 30px;
				display: block;
				margin-left: 40px;
				@include break($neutral) {
					margin-left: auto;
					margin-bottom: 0;
					margin-top: 0;
				}
			}

			p.text {
				max-width: 200px;
				float: left;
				margin-left: 20px;
				@include break($neutral) {
					float: none;
					text-align: center;
					max-width: none;
				}

			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				left: 90px;
				top: -90px;
				width: 262px;
				height: 168px;
				background: url('../images/arrow-gray.png') 100% bottom no-repeat;
				transform: rotate(95deg);
				@include break($large) {
					top: -130px;
				}
				@include break($medium) {
					top: -80px;
				}
				@include break($neutral) {
					display: none;
				}

			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				left: 368px;
				top: 30px;
				width: 31px;
				height: 76px;
				background: url('../images/arrow-small.png') 100% bottom no-repeat;
				transform: rotate(-120deg);
				@include break($medium) {
					left: 300px;
				}
				@include break($neutral) {
					display: none;
				}

			}
		}


		&.item-5 {
			@include break($neutral) {
				order: 4;
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				left: -80px;
				top: 0;
				width: 50px;
				height: 50px;
				background: url('../images/arrow-very-small.png') 100% bottom no-repeat;
				@include break($medium) {
					left: -50px;
				}
				@include break($neutral) {
					display: none;
				}

			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				left: -25px;
				top: -190px;
				width: 262px;
				height: 168px;
				background: url('../images/arrow-gray.png') 100% bottom no-repeat;
				@include break($large) {
					left: -60px;
				}
				@include break($neutral) {
					display: none;
				}

			}

			img {
				margin-bottom: -20px;
			}

			p.text {
				@include break($neutral) {
					float: none;
					text-align: center;
					max-width: none;
				}
			}
		}

		&.item-6 {
			float: right;
			@include break($neutral) {
				order: 6;
			}

			img {
				margin-bottom: -100px;
				@include break($medium) {
					margin-right: -20px;
				}
			}

			p.text {
				clear: both;
				float: right;
				max-width: 260px;
				@include break($neutral) {
					float: none;
					text-align: center;
					max-width: none;
				}

			}
		}


		div.item-2,
		div.item-3,
		div.item-4,
		div.item-5,
		div.item-6 {
			@include break($neutral) {
				padding-top: 100px !important;
				position: relative;
				&::after {
					background: url('../images/arrow-very-small.png') 50% top no-repeat;
					transform: rotate(50deg);
					display: block;
					content: "";
					position: absolute;
					top: 20px;
					left: 50%;
					width: 50px;
					height: 50px;
					margin-left: -25px;
				}
			}
		}


	}

	p {
		@include font(18px, 1.3, #7d6d67, 400);
		font-family: $courgette;
		@include break($large) {
			font-size: 16px;
		}
		@include break($neutral) {
			font-size: 18px;
		}

		strong {
			display: block;
			@include font(24px, 1.2, #365b70, 400);
			padding-bottom: 10px;
			@include break($large) {
				font-size: 20px;
			}

			& + br {
				display: none;
			}

			@include break($neutral) {
				font-size: 24px;
			}
		}

		b {
			display: block;
			@include font(30px, 1.4, #da3e58, 400);
			padding-bottom: 10px;

			& + br {
				display: none;
			}

			@include break($medium) {
				font-size: 24px;
			}
			@include break($neutral) {
				font-size: 28px;
				padding-right: 25px;
			}
			@include break($x-small) {
				padding-right: 40px;
			}

		}
	}
}


#possibilities {
	padding: 65px 0;
	background: url(../images/bg-section-possibilities.svg) no-repeat center / cover;
	.wrapper {
		margin-bottom: 15px;
		background-color: #fff;
		border-radius: 50px;
		h3 {
			margin: 20px 20px 0;
			padding: 10px 15px 14px;
			border-radius: 30px;
			font-family: $second_font;
			@include font(30px,1.2,#fff);
			background-color: #e7e700;
		}
		p {
			padding: 0 20px 10px;
			font-size: 15px;
		}
		.text {
			padding: 20px 20px 45px;
			text-align: center;
			p {
				font-family: $second_font;
				@include font(24px,1.2,#1a1a1a);
			}
		}
		&.item-green {
			h3 {
				background-color: #55d94a;
			}
			.text {
				p {
					color: #55d94a;
				}
			}
		}
		&.item-pink {
			h3 {
				background-color: #ff7b91;
			}
			.text {
				p {
					color: #ff7b91;
				}
			}
		}
		&.item-violet {
			background-color: #000000;
			h3 {
				background-color: #511eff;
			}
			p {
				color: #fff;
			}
			ul.list {
				margin: 0 20px;
				padding-left: 3px;
				li {
					padding-bottom: 5px;
					font-size: 15px;
					color: #fff;
					&:before {
						position: relative;
						top: -2px;
						@include circle(7px,#9779ff);
						margin-right: 15px;
					}
				}
			}
			.text {
				p {
					color: #511eff;
				}
			}
		}
	}
}