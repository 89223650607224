/* -------------------------------------------------------------------------------------------------------------------
                                                  Footer
------------------------------------------------------------------------------------------------------------------- */
.site-footer {
  background: #2e3742;
  padding: 28px 0 32px;
  div.copyright {
    font-family: $courgette;
    @include font(16px, 1.3, #fff, 400);
    text-align: left;
  }
}


#cookie {
  height:0;
  @include link_trans();  
  overflow: hidden;
  padding:0;
  &.open-modal {
    height: auto;
    background: #ff7c92;
    padding:  16px 0;
    width: 100%;
    position: fixed;
    bottom:0;
    left:0;
    z-index: 1000;
  }
  p {
    padding: 14px 0;
    @include font(16px, 1.3, #fff, 400);
    font-family: $courgette;
    margin-bottom: 0;
    float: left;
    @include break($medium) {
      max-width: 720px;
    }      

  }
  a.cookie-ok {
    float: right;
    @include font(16px, 48px, #ff7d93, 400);
    width: 180px;
    height: 48px;
    border-radius: 28px;
    text-align: center;     
    text-decoration: none;
    background: #fff;
    font-family: $courgette;
    cursor: pointer;
    &:hover {
      background: #9fb706;
      color: #fff;
    }
    @include break($medium) {
      margin-top: 10px;
    }      

  }
}
