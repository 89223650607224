/* General site layout controls.
------------------------------------------------------------ */
#content {
  position: relative;
  z-index: 1;
}

.container {
  @include break($mobile) {
    width: 100%;
  }

}
.site-all{
  width: 100%;
  overflow: hidden;
}
.site-content {
  position: relative;
  clear: both;
  .not-home & {
    padding-top: 50px;
    background: #fff;
    @include break($medium) {
      padding-top: 30px;
      padding-bottom: 25px;
    }
    @include break($neutral) {
      padding-top: 10px;
      padding-bottom: 15px;
    }
  }

  @include break($mobile) {
    padding-top: 0;
    padding-bottom: 0;
  }
  /*&::before,
  &::after {
      content: '';
      position: absolute;
      height: 72px;
      top: -72px;
      left: 18px;
      width: 100%;
      transform: skewX(-26deg);
      background: #fff;
      display: block;
  }
  */
}
.block{
  .center{
    margin: 0 auto;
    display: block;
  }
}

// Sidebar left page layout.
.page-template-page-sidebar-left {
  .site-content > .container {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background: #dcdcdc;
      left: 25%;
      margin-left: -7px;
      display: block;
      @include break($neutral) {
        display: none;
      }
    }
  }
  .sidebar-left {
    padding-right: 20px;
    @include break($neutral) {
      padding-right: 10px;
    }
  }
}

// Blog page layout.
.page-template-page-blog {

}

@include break($neutral) {
  /* div[class*='col-md-'] + div[class*='col-md-'] {
    margin-top: 30px;
  } */
}

// Mobile rules.
@include break($mobile) {

}

/* -------------------------------------------------------------------------------------------------------------------
                                                  Sidebar section
------------------------------------------------------------------------------------------------------------------- */
.sidebar {
  position: relative;
  z-index: 1;

  // Widget titles.
  h3 {
    @include font(24px, 1.1, $blue, 700);
    position: relative;
    margin-bottom: 40px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 0;
      width: 100%;
      height: 3px;
      background: #dcdcdc;
    }
    &::before {
      background: #e6b021;
      width: 60px;
      z-index: 1;
    }
  }

  // Default widget.
  .widget {
    margin-bottom: 10px;
    &.widget-last {
      margin-bottom: 0;
    }
  }

  // Menu widget.
  .widget_nav_menu {
    li {
      margin-bottom: 5px;
      a {
        text-align: center;
        position: relative;
        display: block;
        font-family: $second_font;
        @include font(22px, 1, #003062);
        border: #e3e3e3 6px solid;
        text-decoration: none;
        padding: 15px 0;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border: 1px solid #fff;
        }
        &:hover {
          color: #fff;
          border-color: #dfc15e;
          background: #dfc15e;
          &::before {
            border-color: #edd78e;
          }
        }
      }
      &.current-menu-item {
        a {
          background: #e3e3e3;
          border-color: #e3e3e3;
          color: #003062;
          &::before {
            border-color: #fff;
          }
        }
      }
    }
  }
}

.sidebar-right {

}

/* -------------------------------------------------------------------------------------------------------------------
                                                 Default Components
------------------------------------------------------------------------------------------------------------------- */
// Article info: date, author, category, tag.
.post-meta {
  font-size: 13px;
  margin-bottom: 15px;
  font-weight: 300;
  .fa {
    font-size: 14px;
  }
  time {
    font-style: italic;
  }
  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .tags {
    padding-top: 5px;
  }
}

/* FlexSlider core styles.
------------------------------------------------------------ */
.flexslider {
  position: relative;
  //overflow: hidden;

  .slides {
    img {
      width: 100%;
      display: block;
      -moz-user-select: none;
      height: auto;
    }
  }

  .flex-pauseplay span {
    text-transform: capitalize;
  }

  /* Clearfix for the .slides element */
  .slides:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  html[xmlns] .slides {
    display: block;
  }

  * html .slides {
    height: 1%;
  }

  /* No JavaScript Fallback */
  /* If you are not using another script, such as Modernizr, make sure you
   * include js that eliminates this class on page load */
  .no-js .slides > li:first-child {
    display: block;
  }
}

/* 404 Page
------------------------------------------------------------ */
.error404 {
  .content {
    padding-bottom: 5%;
    text-align: center;
    @include break($mobile) {
      width: 100%;
    }
    h2 {
      font-size: 90px;
      line-height: 90px;
      margin-bottom: 40px;
      @include break($mobile) {
        line-height: 1;
        font-size: 60px;
      }
      @include break($x-small) {
        font-size: 40px;
      }
    }
    h3 {
      font-size: 190px;
      line-height: 190px;
      font-weight: 700;
      @include break($mobile) {
        line-height: 1;
        font-size: 120px;
      }
      @include break($x-small) {
        font-size: 70px;
      }
    }
    p {
      text-align: center;
      font-size: 20px;
      line-height: 29px;
      @include break($mobile) {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
  .site-inner input[type="text"] {
    padding: 7px 10px 9px;
    width: 190px;
    margin-top: 1px;
  }
  .searchform .search-submit {
    float: none;
  }
  .searchform .searchsubmit {
    line-height: 40px;
    vertical-align: top;
  }

}

/* Post Navigation (prev/next buttons).
------------------------------------------------------------ */
body .posts-navigation {
  @include clearfix();
  padding: 0 5px;
  a {
    i {
      transition: .3s;
      position: relative;
      line-height: 0;
    }
    &[rel='prev'] {
      i {
        transform: translateX(-5px);
      }
      &:hover i {
        transform: translateX(-10px);
      }
    }
    &[rel='next'] {
      i {
        transform: translateX(5px);
      }
      &:hover i {
        transform: translateX(10px);
      }
    }
    &:last-child {
      float: right;
    }
  }
}

/* Post Pagination.
------------------------------------------------------------ */
.pagination-custom {
  @include clearfix();
  padding-bottom: 30px;
  @include break($mobile) {
    text-align: center;
  }
  > * {
    display: inline-block;
    font-size: 14px;
    line-height: 36px;
    margin: 0 15px 6px 0;
    text-align: center;
    width: 40px;
    border: solid 2px #000000;
    text-decoration: none;
    background: #fff;
  }
  a {
    color: #000;
    i {
      position: relative;
    }
    &[rel='prev'] {
      &:hover i {
        transform: translateX(-10px);
        color: #eee;
      }
    }
    &[rel='next'] {
      &:hover i {
        transform: translateX(10px);
        color: #eee;
      }
    }
    &.custom {
      width: auto;
    }
    &:hover {
      color: #000;
      border-color:#7e394e;
      text-decoration: none;
    }
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
  span.current {
    background: #000;
    color: #fff;
  }
}

/* Search results.
------------------------------------------------------------ */
.search-results {
  .archive-description {
    display: block;
  }
  article {
    border-bottom: 1px dotted #bbb;
    margin-bottom: 20px;
    padding-bottom: 25px;
    overflow: hidden;
  }
  h2 {
    text-align: left;
    margin-bottom: 35px;
    a {
      text-decoration: none;
    }
  }
  h3 {
    font-size: 20px;
  }
  .featured-thumbnail {
    display: none;
  }
  .entry-content,
  .excerpt {
    overflow: hidden;
    margin-bottom: 10px;
  }
  .details {
    float: right;
  }
}

/* Form fields.
----------------------------------------------------------- */
.site {
  form .row {
    margin-bottom: 10px;
  }
  label {
    @include font(14px, 1.5, #000, 400);
    cursor: pointer;
    display: inline-block;
    padding-bottom: 7px;
    sup {
      color: $red;
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='url'],
  input[type='search'],
  input[type='date'],
  textarea,
  select,
  .select2-selection .select2-selection__rendered {
    font-family: $courgette;
    @include font(16px, 1.2, #444f5c);
    outline: none;
    background: #d6cecb;
    display: block;
    padding: 12px 16px 14px;
    width: 100%;
    height: 41px;
    border: 0;
    border-radius: 20px;
    margin-bottom: 5px;

    //border-radius: 3px;
    &:hover {  }
    &:focus {  }
    // Placeholders.
    ::-webkit-input-placeholder {
      color: #000;
    }

    ::-moz-placeholder {
      color: #000;
    }

    :-ms-input-placeholder {
      color: #000;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='url'],
  input[type='search'] {
  }

  textarea {
    height: 180px;
    padding: 15px;
    overflow: auto;
  }

  select {
  }


}

/* Contact form common styles.
------------------------------------------------------------ */
div.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  border: 0;
}

div.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
}

div.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
}

div.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
}

div.wpcf7-validation-errors {
  border: 2px solid #f7e700;
}

.wpcf7-display-none {
  display: none;
}

div.wpcf7 img.ajax-loader {
  border: none;
  vertical-align: middle;
  margin-left: 4px;
}

div.wpcf7 div.ajax-error {
  display: none;
}

// Theme styles.
.form-decor {
  position: relative;
  z-index: 15;
  margin-top: -125px;
}

div.wpcf7 {
  .form-wrapper {
    padding: 27px 38px 30px;
    border: 1px solid #d8d8d8;
  }

  .form-title {
    position: relative;
    padding: 20px 0px 40px 120px;
    margin-top: 15px;
    @include font(30px, 1.3, #444f5c, 400);
    font-family: $courgette;
    background: url('../images/letter-pic.png') 15px top no-repeat;
  }

  // Reduce recaptcha size on mobile via hack.
  @include break($x-small) {
    .g-recaptcha {
      transform: scale(0.83);
      transform-origin: 0 0;
    }
  }

  .row > div {
    margin: 0;
    @include break($x-small) {
      width: 100%;
    }
  }

  p {
    padding: 0;
    @include clearfix();
  }
  br {
    display: none;
  }

  label {
    cursor: pointer;
    font-size: 16px;
    color: #313131;
    display: block;
    padding: 0;
    margin-bottom: 6px;
    font-family:$third_font;
    i{
      color: #c30016;
    }
  }

  .wpcf7-form-control-wrap {
    display: block;
    position: relative;
    // Captcha field wrapper.
    input, textarea{
      margin-bottom: 15px;
    }
    &.captcha {
      float: left;
      width: 70px;
      span.wpcf7-not-valid-tip {
        width: 70px;
        background: none;
      }
    }
  }

  // Checkbox group.
  .wpcf7-checkbox {
    display: block;
    padding-top: 10px;
    @include clearfix();
    span.wpcf7-list-item {
      width: 50%;
      float: left;
      padding-bottom: 2px;
      @include break($x-small) {
        width: 100%;
      }
      span {
        position: relative;
        display: inline-block;
        padding: 0 0 0 24px;
        &::before {
          content: '';
          width: 12px;
          height: 12px;
          position: absolute;
          top: 3px;
          left: 0;
          border: 1px solid #d6d6d6;
          background: #fff;
          border-radius: 2px;
        }
      }
      input[type="checkbox"] {
        display: none;
        &:checked {
          + span {
            color: #000;
            &::before {
              border-color: transparent;
              background: #c1c1c1;
            }
          }
        }
      }
    }
  }

  // Submit row.
  .submit-row {
    @include clearfix();
    > div {
      float: left;
    }
    > p {
      float: right;
    }
  }

  // Submit button.
  .wpcf7-submit {
    @extend .btn;
    margin-left: auto;
    margin-right: auto;
    float: none;
    display: block;
  }

  .g-recaptcha {
    float: none;
    margin: 0 auto 20px;
    width: 300px;
    @include break($medium) {
      transform: scale(0.8);
      transform-origin: 0 0;
    }
  }

  // Error message.
  span.wpcf7-not-valid-tip {
    font-weight: 300;
    padding-top: 0;
    color: #f04028;
    font-size: .85em;
    display: block;
    position: relative;
    margin-top: -15px;
    margin-bottom: 10px;
    margin-left: 15px;
  }

  div.wpcf7-response-output {
    padding: 10px 20px;
    text-align: center;
    font-size: 13px;
    margin: 10px 0 0;
    background: #fff;
    bottom: 0;
    @include break($medium) {
      padding: 5px 10px;
    }
  }

  div.wpcf7-validation-errors {
    background: #fde8b1;
    color: #222;
    clear: both;
  }
  div.wpcf7-mail-sent-ok {
    background: #ccff99;
    border: 2px solid #398f14;
  }

  img.ajax-loader {
    display: block;
    background: #fff;
  }

  div.disclaimer {
    //font-style: italic;
    font-size: 0.8em;
    padding-bottom: 15px;
  }
}

/* Breadcrumbs.
------------------------------------------------------------ */
.breadcrumbs {
  @include font(14px, 1.2, #4a4a4a, 300);
  padding: 11px 0 12px;
  background: #e9e9e9;
  text-align: left;
  font-family:$third_font;
  text-transform: uppercase;
  @include break($mobile) {
    padding: 11px 10px 10px;
    font-size:12px;
  }
  span[typeof="ListItem"] {
    display: inline-block;
    // Separator arrow.
    + i {
      margin: 0 10px;
      color: #707070;
      position: relative;
      font-family:$third_font;
      @include break($mobile){
        margin: 0 4px;
      }
    }
  }
  a {
    text-decoration: none;
    color: inherit;
    @include break($mobile) {
    }
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: $hover_color;
    }
  }
}

/* Page title.
------------------------------------------------------------ */
.page-title-row {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url("../images/subpage-bg-header.jpg");
  position: relative;
  //height: 295px;
  z-index: 11;
  clear: both;
  padding: 14vw 0 9vw;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  @include break($mobile) {
  }
  @include break($x-small) {
  }

  .page-title {
    z-index: 1;
    text-align: center;
    div.title {
      position: relative;
      z-index: 2;
      opacity: 0;
      transform: scale(.9);
      @include font(74px, 1.2, #fff, 300);
      text-transform: uppercase;
      font-family:$main_font;
      //text-shadow: 2px 2px 3px rgba(0, 0, 0, .7);

      .loaded & {
        transition: all 0.3s ease-out;
        opacity: 1;
        transform: scale(1);
      }
      h1{
        @include inherit();
      }
    }
  }
  &.no-image {
    .page-title {
      //width: 100%;
      &::after {
        //display: none;
      }
    }
  }
}

/* Post formats -> standard.
----------------------------------------------------------- */
article.format-standard {
  overflow: hidden;
  padding-bottom: 40px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #dfdfdf;
  .entry-header {
    h3 {
      margin-bottom: 0.4em;
      a {
        color: $blue;
        text-decoration: none;
        &:hover {
          color: $hover_color;
        }
      }
    }
  }

  .featured-thumbnail {
    float: left;
    margin: 0 30px 0 0;
    line-height: 0;
    @include break($medium) {
      max-width: 40%;
    }
    @include break($neutral) {
      max-width: 100%;
      float: none;
      margin: 0 0 30px 0;
    }
    a {
      display: block;
      overflow: hidden;
      @include break($mobile) {
        display: inline-block;
      }
    }
  }

  .post-content {
    overflow: hidden;
    padding-bottom: 15px;
  }

}

/* Search form.
----------------------------------------------------------- */
.search-form {
  position: relative;
  max-width: 400px;
  .search-field {
  }
  input[type='submit'] {
    background: url("../images/icons/icon-search.png") no-repeat 50% 50%;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    width: 30px;
    height: 100%;
    transition: .3s;
    &:hover,
    &:active,
    &:focus {
      background: url("../images/icons/icon-search.png") no-repeat 50% 50%;
      opacity: .7;
    }
  }
}

/* Back to top main button.
----------------------------------------------------------- */
#back_to_top {
  position: fixed;
  right: 10px;
  bottom: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  display: none;
  font-size: 25px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  z-index: 4;
  transition: .2s;
  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  .touch & {
    display: none;
    z-index: -1;
  }
}

/* Site loader icon.
----------------------------------------------------------- */
.site-preloader {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: $white_light url("../images/balls.svg") no-repeat 50% 50%;
  text-align: center;
  // Enable preloader for mobile only.
  @include break($not-mobile) {
    display: none;
  }
  .loaded & {
    opacity: 0;
    z-index: -1;
  }
}

/* Touch indicator.
------------------------------------------------------------ */
.touch-indicator {
  position: absolute;
  bottom: 50%;
  text-align: center;
  z-index: 3;
  background: url("../images/touch-indicator.png") 50% 50% no-repeat;
  height: 72px;
  animation: shake 2s infinite;
  opacity: 0;
  width: 69px;
  left: 50%;
  margin-left: -35px;
  margin-bottom: -36px;
  .loaded & {
    opacity: 1;
    transition: opacity .5s;
  }
  &.disabled {
    opacity: 0;
    transition: .5s;
  }
}

/* Video wrapper.
------------------------------------------------------------ */
.content-video {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  @include break($mobile){
    margin-bottom: 40px;
  }              

  iframe {
    position: absolute;
    background: url("../images/balls.svg") no-repeat 50% 50% #eee;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

/* -------------------------------------------------------------------------------------------------------------------
																								 Widgets
------------------------------------------------------------------------------------------------------------------- */
/* Recent posts.
------------------------------------------------------------ */
.widget_recent_entries {
  h3 {
  }
  li {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #eaeaea;
    a {
      @include font(14px, 1.5, $blue, 700);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: #005ca9;
      }
    }
  }
}

/* Social icons.
------------------------------------------------------------ */
.social-icons {
  h4 {
    text-transform: none;
    margin-bottom: 1em;
  }
  a {
    text-decoration: none;
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 4px;
    text-align: center;
    transition: .2s ease-in-out;
    @include font(20px, 1, #fff, 400);
    font-family: 'FontAwesome';

    &:hover {
      color: $hover_color;
    }
  }
}

/* Text blocks (settings for background images).
------------------------------------------------------------ */
.text-block {
  background-repeat: no-repeat;
  background-size: cover;
  &.background-static {
    background-position: 50% 50%;
  }
  &.background-parallax {
    background-position: 50% 0;
    background-attachment: fixed;
  }
  .touch & {
    background-attachment: scroll;
  }
}

/* Map (normally on contact page).
------------------------------------------------------------ */
.block-map {
  //background: #fefefe;
  /*iframe {
    width: 100%;
    background: url("../images/balls.svg") no-repeat 50% 50%;
    //border: 1px solid #ddd;
    max-height: 410px;
    @include break($mobile) {
      max-height: 250px;
    }
  }*/
  position: relative;
  padding-bottom: 369px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background: url("../images/balls.svg") no-repeat 50% 50%;
  }
}

/* Gallery single page.
------------------------------------------------------------ */
.gallery {
  div[class^='col-'] {
    margin-bottom: 20px;
    margin-top: 0;
    @include break($x-small) {
      width: 100%;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
      float: none;
    }
    a {
      display: block;
      border: 5px solid $white_light;
      background: $blue;
      line-height: 0;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        transition: .3s;
      }
      .zoom-icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        cursor: pointer;
        overflow: hidden;
        transition: .55s;
        &:before {
          content: "";
          position: absolute;
          left: -50%;
          top: 48%;
          display: block;
          height: 0;
          width: 200%;
          background: rgba(39, 72, 122, .5);
          transform: rotate(45deg);
          transition: .65s;
        }
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 47px;
          height: 47px;
          line-height: 47px;
          margin-top: -23px;
          margin-left: -23px;
          font-size: 22px;
          background: $red;
          color: #fff;
          border-radius: 50%;
          text-align: center;
          transform: scale(2);
          opacity: 0;
          transition: .45s;
        }
      }
      // Hover state.
      .non-touch &:hover {
        .zoom-icon {
          &:before {
            left: -60%;
            top: -100%;
            height: 400%;
          }
          i {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }
    strong {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      display: block;
    }
  }
}

/* Testimonials items.
------------------------------------------------------------ */
.testimonials-list {
  padding-bottom: 10px;
  .item {
    position: relative;
    padding: 0 0 30px 45px;
    margin-bottom: 35px;
    &:before {
      content: "\f10e";
      font-family: $font_awesome;
      color: #114181;
      position: absolute;
      top: 0;
      left: 0;
      font-style: normal;
      font-size: 24px;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 190px;
      height: 1px;
      background: #e1e1e1;
      display: block;
    }
    .title {
      padding-top: 20px;
      padding-left: 130px;
      background: url(../images/review-stars.png) 0 20px no-repeat;

    }
  }
}

/* Page animation overlay color.
------------------------------------------------------------ */
.bg-init,
.animsition-overlay-slide {
  background-color: $blue_light;
}