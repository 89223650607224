/* Tiny MCE styles. */
#tinymce{
	/* General elements
    ------------------------------------------------------------ */
	body {
		background: #fff;
		font-size: 14px;
	}


	/* Buttons & Links
    ------------------------------------------------------------ */

	.list {
		list-style: none;
	}
}
