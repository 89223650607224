/* -------------------------------------------------------------------------------------------------------------------
                                                  Header elements
------------------------------------------------------------------------------------------------------------------- */

.slider-area {
	padding-top: 80px;
	background: rgba(137, 187, 209, 0.3) url('../images/back-curtain.png') 50% top no-repeat;
	background-size: 100% 100%;
	position: relative;
	z-index: 3;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 128px;
		top: 25px;
		position: absolute;
		background: url('../images/group-curtain.png') 50% top repeat-x;
		z-index: 2;
		@include break($mobile) {
			background-size: 480px;
			background-position: 50% 30px;
		}
	}

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 140px;
		bottom: 0;
		position: absolute;
		background: #d6cecb;
		z-index: 1;
		@include break($mobile) {
			height: 50px;
		}

	}


	.slider-wrap {
		text-align: center;
		position: relative;
		z-index: 4;
		padding-bottom: 30px;

		@include break($neutral) {
			padding-bottom: 0;
		}

		&::before {
			content: '';
			display: block;
			height: 100%;
			width: 310px;
			top: -10px;
			right: 0;
			position: absolute;
			background: url('../images/left-curtain.png') left top no-repeat;
			z-index: 2;
			transform: scaleX(-1);
			@include break($neutral) {
				right: -80px;
			}
			@include break($mobile) {
				display: none;
			}
		}

		&::after {
			content: '';
			display: block;
			height: 100%;
			width: 310px;
			top: -10px;
			position: absolute;
			background: url('../images/left-curtain.png') right top no-repeat;
			z-index: 1;
			@include break($neutral) {
				left: -80px;
			}
			@include break($mobile) {
				display: none;
			}
		}

		& > .text-block {
			padding: 100px 0 20px;
			@include break($mobile) {
				padding-top: 60px;
			}

			& > p:first-child {
				padding-bottom: 20px;

				& + img {
					@include break($mobile) {
						max-width: 220px;
					}
				}
			}
		}

		p {
			@include font(24px, 1.3, #444f5c, 400);
			font-family: $courgette;
			@include break($x-small) {
				font-size: 20px;
			}

			em {
				font-style: normal;
			}
		}
	}

	img.wp-image-31 {
		@include break($medium) {
			max-width: 90%;
		}
		@include break($mobile) {
			max-width: 320px;
		}
	}
}

.site-header {
	position: relative;
	background: #fff;

	.header-wrap {
		.contacts {
			position: absolute;
			right: 30px;
			top: 20px;
			z-index: 100;
			@include break($medium) {
				top: 22px;
				right: 10px;
			}
			@include break($mobile) {
				right: 50px;
			}

			a.phone {
				@include font(18px, 1, #ff99aa, 700);
				letter-spacing: -0.3px;
				font-family: $montserrat;
				float: left;
				text-decoration: none;
				margin-top: 2px;
				padding-right: 30px;
				position: relative;
				@include break($neutral) {
					margin-top: 5px;
					font-size: 14px;
					padding-right: 10px;
				}
				@include break($mobile) {
					font-size: 18px;
				}
				@include break($x-small) {
					font-size: 16px;
					font-weight: 400;
				}

				&:before {
					content: '';
					position: absolute;
					display: block;
					font-family: 'FontAwesome';
					@include font(18px, 40px, #ff99aa);
					top: -11px;
					left: -25px;
					@include break($neutral) {
						display: none;
					}
				}

			}

			.social-icons {
				float: right;
				margin-right: 0;

				@include break($mobile) {
					padding-right: 20px;
					padding-left: 20px;
					padding-top: 0;
				}
				@include break($x-small) {
					padding-right: 2px;
					padding-left: 2px;
				}

				a {
					width: 39px;
					height: 99px;
					display: block;
					background: url('../images/facebook.png') right top no-repeat;
					position: fixed;
					right: 0;
					top: 130px;
					margin-right: 0;
					border-radius: 4px 0 0 4px;
					@include break($mobile) {
						width: auto;
						height: auto;
						background: none;
						position: relative;
						top: 0;
						font-size: 25px;
					}

					i {
						display: none;
						@include break($mobile) {
							display: block;
						}
					}

				}
			}

		}
	}

	.site-logo {
		z-index: 9;
		position: absolute;
		left: 30px;
		top: 12px;
		@include break($neutral) {
			left: 12px;
		}
		@include break($x-small) {
			width: 90px;
		}

		a {
			text-decoration: none;
		}

		.logo-text {
			display: inline-block;
			vertical-align: top;
			padding: 7px 0 0 12px;
			@include font(15px, 1, #fff, 400);
			letter-spacing: 7.2px;
			text-align: center;

			strong {
				font-weight: 600;
				font-size: 40px;
				letter-spacing: 0;

				em {
					font-weight: 300;
					font-style: normal;
				}
			}
		}
	}

	.container {
		position: relative;
	}

	p {
		padding: 0;
	}

	.row-top {
		position: absolute;
		top: 0;
		left: 0;
		min-height: 60px;
		background: #00255c;
		z-index: 50;
		width: 100%;
		text-align: center;
		@include break($mobile) {
			position: fixed;
		}

		&:after {
			display: block;
			position: absolute;
			top: 1px;
			left: 0;
			width: 100%;
			background: url('../images/top-curtain.png') left top repeat-x;
			height: 80px;
			content: '';
			z-index: 0;
			@include break($x-small) {
				top: -10px;
			}
		}

		&.sticky {
			position: fixed;
			left: 0;
			width: 100%;
			transition: .3s;
			opacity: 1;
			z-index: 11;

			.admin-bar .sticky & {
				top: 32px;
			}

			.site-logo {
			}

			.header-menu .main-menu > li {
				> a {
				}

				> ul.sub-menu {
				}
			}

			@include break($mobile) {
				display: none;
			}
		}

	}


	// Fixed scrolling row on mobile.
	.row-mobile {
		position: fixed;
		height: 45px;
		top: 11px;
		right: 0;
		z-index: 100;
		width: 58px;

		.icons-mobile {
			text-align: right;
			padding: 0 15px 0 0;

			a {
				margin-left: 10px;
				color: #fff;
				font-size: 26px;
			}
		}
	}

	.accoun-link {
		display: block;
		vertical-align: top;
		font-family: $third_font;
		@include font(13px, 40px, #fff);
		padding: 0 24px 0 57px;
		position: relative;
		border-left: 1px solid $grey_black;
		float: right;
		background: none;
		@include break($mobile) {
			border-left: 0;
			padding: 0 0 0 0;
			z-index: 1000;
			width: 45px;
		}

		&:before {
			content: '\e005';
			position: absolute;
			display: block;
			font-family: $simple_line_icons;
			@include font(20px, 40px, #fff);
			top: 0;
			left: 27px;
			@include break($mobile) {
				left: 18px;
			}
		}

		a {
			@include inherit();
			text-transform: uppercase;
			text-decoration: none;
			@include break($mobile) {
				text-indent: -9999px;
				display: block;
				padding-right: 20px;
				display: block;
				position: relative;
				z-index: 1001;
			}

		}

		&:hover {
			background: $grey_black;
			@include break($mobile) {
				background: none;
			}
		}
	}

	.shopping-container {
		display: inline-block;
		cursor: pointer;
		vertical-align: top;

		.cart-popup {
			height: 0;
		}
	}

}


/* Primary menu.
------------------------------------------------------------ */
.top-row-menu {
	position: relative;
	display: inline-block;
	padding-top: 20px;

	nav {
		@include break($medium) {
			text-align: right;
		}
	}
}

.header-menu {

	// Common styles.
	.main-menu {

		li {
			display: block;
			z-index: 2;
			position: relative;

			a {
				text-decoration: none;
				position: relative;
				display: block;
				font-family: $montserrat;
			}

			span {
				position: relative;
			}
		}

		// 1st level menus.
		> li {
			padding: 0 10px;
			display: inline-block;

			&:first-child {
				padding-left: 0;
			}

			@include break($large) {
			}
			@include break($medium) {
				float: none;
				display: inline-block;
				padding: 0 2px;
			}
			@include break($neutral) {

			}


			> a {
				font-family: $montserrat;
				@include font(16px, 1, #fff, 400);
				text-transform: uppercase;
				letter-spacing: -0.3px;
				padding-bottom: 4px;
				border-bottom: 2px transparent solid;
				@include break($large) {
					font-size: 14px;
				}
				@include break($medium) {
					font-size: 12px;

				}
				@include break($neutral) {
					line-height: 28px;
					font-size: 10px;
				}

				// Parent menu item.
				&.sf-with-ul {
					padding-right: 20px;
					// Bottom arrow.
					&::after {
						content: '\f107';
						font-family: $font_awesome;
						font-size: 12px;
						font-weight: 500;
						line-height: 45px;
						right: 5px;
						//border-style: solid;
						//border-width: 6px 5px 0 5px;
						//border-color: #c0c0c0 transparent transparent transparent;
						position: absolute;
						@include break($neutral) {
						}
					}
				}

				// Hover root item.
				&:hover,
				&:focus {
					border-bottom-color: #ff7c92;

					&::before {
						//transform: scale(1);
					}

					&::after {
					}
				}
			}

			&.special {
				a {
					color: $red;
					font-weight: 700;
				}
			}

			&.btn-header {
				> a {
					@extend .btn-custom;
				}
			}

			// Parent root menu item.
			&.menu-item-has-children {
				// Keep activity for the root item while viewing dropdown.
				&.sfHover > a {
					//background: #2a6db3;
					&::before {
						transform: scale(1);
					}

					&::after {
						border-color: #fff transparent transparent transparent;
					}
				}
			}

			// Active root menu item.
			&.current_page_item,
			&.current-menu-item,
			&.current-page-ancestor,
			&.current-menu-ancestor {
				> a {
					color: #fff;

					&::before {
						transform: scale(1);
					}
				}
			}

			// Animate dropdown menu.
			&.sfHover,
			&:hover {
				> ul.sub-menu {
					transform: translateY(0);
					opacity: 1;
					visibility: visible;
				}
			}

			// 2nd Level.
			> ul.sub-menu {
				background: #fff;
				border: solid 1px #979797;
				border-top: none;
				padding: 23px 15px;
				display: none;
				position: absolute;
				top: 45px;
				left: 0;
				z-index: 2;
				width: 250px;
				opacity: 0;
				visibility: hidden;
				transition: all .4s ease-out;
				//transform: translateY(30px);
				@include break($medium) {
				}

				> li {
					// Current sub menu item.
					&.current_page_item > a,
					&.current-menu-ancestor > a,
					&.current-menu-item > a {
						color: #fff;
						background: $reb_dark;
					}

					> a {
						padding: 7px 10px 7px 7px;
						font-size: 14px;
						color: #000;
						font-family: $main_font;
						text-align: left;
						@include break($medium) {
						}
						@include break($neutral) {
						}

						&.sf-with-ul {
							padding-right: 20px;
							// Bottom arrow.
							&::after {
								content: '\f0d7';
								font-family: $font_awesome;
								font-size: 14px;
								font-weight: 500;
								line-height: 14px;
								color: #c6c6c6;
								right: 12px;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
							}
						}

						// Hover sub menu item.
						&:hover {
							color: #69283d;
							background: #fcf1ef;

							&.sf-with-ul {
								padding-right: 20px;
								// Bottom arrow.
								&::after {
									content: '\f0da';
								}
							}
						}
					}

					// 3nd Level.
					ul.sub-menu {
						background: #fff;
						border: solid 1px #979797;
						padding: 23px 15px;
						display: none;
						position: absolute;
						top: 0;
						left: 233px;
						z-index: 2;
						width: 250px;
						opacity: 0;
						visibility: hidden;
						transition: all .4s ease-out;
						//transform: translateY(30px);
						@include break($large) {
							left: -265px;
						}

						> li {
							// Current sub menu item.
							&.current_page_item > a,
							&.current-menu-ancestor > a,
							&.current-menu-item > a {
								color: #fff;
								background: $reb_dark;
							}

							> a {
								padding: 7px 10px 7px 7px;
								font-size: 14px;
								color: #000;
								font-family: $main_font;
								text-align: left;
								@include break($medium) {
								}
								@include break($neutral) {
								}
								// Hover sub menu item.
								&:hover {
									color: #69283d;
									background: #fcf1ef;
								}
							}
						}
					}

					// Animate dropdown menu.
					&.sfHover,
					&:hover {
						> ul.sub-menu {
							transform: translateY(0);
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}
}


/* Mobile menu toggle icon.
------------------------------------------------------------ */
.mobile-menu-icon {
	display: none;
	height: 22px;
	width: 22px;
	z-index: 10;
	color: #fff;
	text-align: center;
	text-decoration: none;
	position: absolute;
	left: 0;
	top: 0;
	left: 15px;
	top: 4px;

	&:hover,
	&:focus,
	&:active {
		color: #fff;
	}

	&::after {
		content: '';
		font-family: $simple_line_icons;
		font-size: 24px;
		color: #ff99aa;
	}

	@include break($mobile) {
		display: block;
	}

	i {
		margin: 0;
	}

	.fa-bars {
		display: block;
	}

	.fa-times {
		display: none;
	}

	.mm-opened & {
		.fa-bars {
			display: none;
		}

		.fa-times {
			display: block;
		}
	}
}

/* Mobile menu theme overrides.
------------------------------------------------------------ */
.mm-menu {
	background: #00255c;

	.mm-listview {

		// Next arrow
		.mm-next {
			background: #000;
			background-image: linear-gradient(to left, #000000, #000);
		}

		// Menu item.
		li a,
		li span {
			color: #ff99aa;
			padding: 8px 10px 8px 20px;
			text-transform: uppercase;
		}

		// Next arrow color.
		> li > a.mm-prev::before,
		> li > a.mm-next::after {
			border-color: #fff;
		}
	}

	// Active item.
	li.current-menu-ancestor > a,
	li.current_page_item > a,
	li.current-menu-item > a {
		color: #fff;
		font-weight: bold;
	}

	// Header menu item.
	.mm-header {
		> a {
			color: #fff;
			font-size: 16px;
		}

		// Slide back arrow.
		.mm-btn {
			background: #000;
			background-image: linear-gradient(to left, #000000, #000);

			&::before,
			&::after {
				border-color: #fff;
			}
		}
	}

}

.mm-listview > li:not(.mm-divider):after {
	left: 0;
}

.mm-menu .mm-listview li a, .mm-menu .mm-listview li span {
	padding-left: 10px;
}

.mm-menu .mm-listview > li:after {
	border-color: #7d6d67;
}